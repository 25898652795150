import React, { useEffect, useState } from "react";
import { logUserAction } from "../store/actions/auth-actions";
import { actionType } from "../../utils/helper";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";

const ClickTracker: React.FC = () => {
    const initialClickCount = parseInt(sessionStorage.getItem("clickCount") || "0", 10);
    const [clickCount, setClickCount] = useState(initialClickCount);
    const access_token = useAppSelector(state => state.auth.user?.access_token);
    const dispatch = useAppDispatch();

    const handleClick = () => {
        if (access_token) { // Ensure clicks are tracked only for authenticated users
            const newClickCount = clickCount + 1;
            setClickCount(newClickCount);
            sessionStorage.setItem("clickCount", newClickCount.toString());
        }
    };

    const sendClickTotalEvent = () => {
        if (access_token) { // Log actions only for authenticated users
            dispatch(
                logUserAction({
                    action_type: actionType["click"],
                    action_log_detail: `Total Click: ${clickCount}`
                })
            );
        }
    };

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        sendClickTotalEvent();
    };

    useEffect(() => {
        if (access_token) {
            // Attach event listener for clicks
            window.addEventListener("click", handleClick);

            // Handle session ending or page refresh
            window.addEventListener("beforeunload", handleBeforeUnload);

            // Cleanup: Remove event listeners when component unmounts
            return () => {
                window.removeEventListener("click", handleClick);
                window.removeEventListener("beforeunload", handleBeforeUnload);
            };
        }
    }, [access_token, clickCount]); // Ensure useEffect re-runs if access_token changes

    return <></>;
};

export default ClickTracker;
